






























































































































import User from "@/models/User";
import { IAccount } from "@/types/interfaces";
import { Component, Vue } from "vue-property-decorator";
import { ActionMethod, mapGetters } from 'vuex';
import { Action, State, Getter } from 'vuex-class';
import Utils from '@/plugins/utils'
const moment = require('moment')

@Component
export default class ProductsPage extends Vue {
  private loading: Boolean = false
  private loadingRed: Boolean = false
  private disconnectingRed: Boolean = false
  public oauthing: Boolean = false
  public uninstallingRed: Boolean = false

  @State(state => state.users.currentUser) currentUser: User;
  @State(state => state.shopify.account) account: IAccount;
  @State(state => state.redProducts) redProducts: {productsToMatch: number, matchedProducts:number, totalProducts:number};

  @Getter('context/getConsumer') consumer: string;
  @Getter('context/getConsumerName') consumerName: string;

  @Action('disconnect', { namespace: 'red' }) disconnect: ActionMethod;

  @Action('oauth', { namespace: 'red' }) redOauth: ActionMethod;
  @Action('uninstall', { namespace: 'red' }) uninstall: ActionMethod;

  disconnectShopify() {
    this.$confirm.show({
      type: "warning",
      buttonText: "Disconnect",
      title: "Disconnect Red",
      text: "Are you sure you want to disconnect your Red store?",
      onConfirm: () => {
        this.disconnect().then(result => {
          this.$notify({ type: 'success', text: 'Your store is disconnected' })
          this.$confirm.hide()
          // this.$router.push({ name: 'users-auth' })
        }).catch(err => {
          this.$notify({ type: 'error', text: err.message })
          this.$confirm.hide()
        }).finally(() => {
          
        });
      }
    });
  }

  goRedOauth() {
    this.oauthing = true
    this.redOauth().then(result => {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = result;
      a.click();

    }).catch(err => {
      this.$notify({ type: 'error', text: err.message })
    }).finally(() => {
      this.oauthing = false
    })
  }

  uninstallRed() {
    this.uninstallingRed = true
    this.uninstall().then(result => {
      this.$notify({ type: 'success', text: 'Your Red store is disconnected' })
    }).catch(err => {
      this.$notify({ type: 'error', text: err.message })
    }).finally(() => {
      this.uninstallingRed = false
    })
  }

  _moment(value: any) {
    return moment(value)
  }
}
